<template>
    <div class="top-bar">
       
        <s-header :name="$t('LAN.submit_feedback')" ></s-header>
    

    </div>
    <div class="feedback-scroll">
        <div class="feedback">
            <!-- 顶部菜单 -->
            <div class="feedback-box" v-if="menus != null" >
                <div class="feedback-item" v-for="(menu, index) in menus" :key="index" :class="{'feedback-item-active' : menuType == menu.type}" @click="selectMenu(menu)" >
                    <img :src="require(`@/assets/` + menu.icon)" class="feed-icon"/>
                    <span class="feed-text">{{ $t(menu.label) }}</span>
                </div>
            </div>
            
            <!-- 表单内容 -->
            <div class="feedback-form" v-if="menus != null">
                <div class="form-item" v-for="(item, index) in menus[menuType].inputs" :key="index">
                    <div class="form-left">
                        <span class="form-label"> {{ $t(item.label) }} </span>
                        <span class="form-icon-required" v-if="item.required">*</span>
                    </div>
                    
                    <div class="form-right">
                        <div class="form-right" @click="onClickItem(item)">
                            <span class="form-value" v-if="item.inputType == 'date'" > {{ item.value }} </span>
                            <input v-bind:readonly="item.readOnly" v-else :type="item.inputType" v-model="item.value" :placeholder="$t(item.placeholder)"  autocomplete="off" class="form-input" >
                            <span v-if="item.money" class="form-currency">￥</span>
                            <span class="form-icon-click"/>
                        </div>
                    </div>

                    <van-popup v-model:show="item.timePicker" round position="bottom">
                        <van-datetime-picker
                            v-model="item.currentDate"
                            type="date"
                            @cancel="item.timePicker = false"
                            @confirm="onConfirmTime(item)"
                            :confirm-button-text="$t('LAN.confirm')"
                            :cancel-button-text="$t('LAN.cancel')"
                            :min-date="minDate"
                            :max-date="maxDate"
                        />
                    </van-popup>

                </div>

                <!-- 上传图片 -->
                <div class="form-item-upload" >
                    <a class="orderLink" v-if="orderLinkShow && menuType == 0" @click="orderHelperShow=true">{{$t('LAN.order_helper')}}</a>
                    <div class="form-item" style="border-bottom: none;">
                        <div class="form-left">
                            <span class="form-label">{{$t('LAN.screenshot')}}</span>
                            <span class="form-icon-required">*</span>
                        </div>
                    </div>
                    <div>
                        <van-uploader v-model="fileList" multiple :max-count="3" :after-read="afterRead"/>
                    </div>
                    <p class="upload-tips"><span>* </span>{{$t('LAN.max_img')}}</p>
                </div>

                <!-- 联系方式 -->
                <div class="form-item form-item-border-top">
                    <div class="form-left">
                        <span class="form-label">{{$t('LAN.contact')}}</span>
                    </div>
                    <div class="form-right">
                        <input v-bind:readonly="emailReadOnly" type="text" v-model="userInfo.email" :placeholder="$t('LAN.contact_email')" autocomplete="off" class="form-input" >
                        <span class="form-icon-click"/>
                    </div>
                </div>

                <!-- 详细描述 -->
                <div class="form-item">
                    <div class="form-left">
                        <span class="form-label">{{$t('LAN.detailed_desc')}}</span>
                        <span class="form-icon-required">*</span>
                    </div>
                </div>
                <div id="content" class="form-item form-item-textarea">
                    <textarea name="detail" v-model="feedContent" :placeholder="$t('LAN.input_feedback')" maxlength="250" class="form-textarea"/>
                </div>
                
                <!-- 提交按钮 -->
                <div style="margin: 16px;">
                    <van-button round block color="#6e87ea" native-type="submit" @click="onSubmit">{{$t('LAN.submit_feedback')}}</van-button>
                </div>
                
            </div>
        </div>

        <!-- 订单帮助 -->
        <van-popup v-model:show="orderHelperShow" round :style="{ height: '80%', width: '90%' }">
            <!-- <iframe src="/helper/feedback/orderhelper"/> -->
            <orderHelper/>
        </van-popup>

    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from 'vue'
import { useStore } from 'vuex'
import sHeader from '@/components/Header'
import { formatDate } from '@/utils/common'
import { Toast } from 'vant'
import uploadFile from '@/utils/uploadFile'
import { feedbackMenus } from '@/configs/feedback'
import { requestFeedback } from '@/service/feedback'
import orderHelper from '@/views/myHelper/feedback/OrderHelper'
import { useI18n } from 'vue-i18n'
import { useRoute } from 'vue-router'
import { validateNull, validateEmail} from '@/utils/validate';
import { CHANNEL } from '@/configs/global'

export default {
    setup() {
        
        const store = useStore()
        const { t } = useI18n()
        const route = useRoute()

        const state = reactive({
            minDate: new Date(2018, 6, 1),
            maxDate: new Date(),

            email: '',
            feedContent: "",

            menuType:0,
            menus: null,

            fileList:[],
            orderHelperShow:false,
            emailReadOnly: false,
            orderLinkShow:false,
            
            userInfo: {
                username:"",
                email:"",
                roleId: 0,
            },

            backLink:''
        });

        onMounted(async () => {
            state.userInfo.username = store.getters.username;
            state.userInfo.email = store.getters.email || "";
            state.userInfo.roleId = store.getters.roleId || "";
            if (Object.keys(route.query).length > 0) {
                state.menuType = parseInt(route.query.menu);
            }
            if (!validateNull(state.userInfo.email)) {
                state.emailReadOnly = true;
            }
            initData();
            
            state.backLink=route.params.L || ''
            //删除指定元素，影响到事件监听
            var myobj = document.getElementsByClassName("block")
            myobj[0].remove();
        });

        const initData = () => {
            state.menus = JSON.parse(JSON.stringify(feedbackMenus));
            state.fileList = [];
            state.feedContent = "";
            state.orderLinkShow = store.getters.channel != CHANNEL.APPLE_STORE;
            console.log(store.getters.roleServerName)
            console.log(store.getters.roleName)
            console.log(store.getters.roleId)
            console.log(store.getters.roleLevel)
            for (const key in state.menus) {
                for (let index = 0; index < state.menus[key].inputs.length; index++) {
                    let data = state.menus[key].inputs[index];
                    data.value = "";
                    if (state.menus[key].type != 4) {
                        if (data.name == "server") {
                            data.value = store.getters.roleServerName || "";
                        }
                        if (data.name == "rolename") {
                            data.value = store.getters.roleName || "";
                        }
                        if (!validateNull(data.value)) {
                            data.readOnly = true;
                        }
                    }
                }
            }
        }

        const selectMenu = (menu) => {
            console.log(menu)
            state.menuType = menu.type
           // console.log(event.target)
        };

        const onClickItem = (item) => {
            if (item.inputType == 'date') {
                item.currentDate = new Date();
                item.timePicker = true
            }
        };

        const onConfirmTime = (item) => {
            item.value = formatDate(item.currentDate, "YY-MM-DD")
            item.timePicker = false;
        }

        const onSubmit = async () => {
            let photos = [];
            for (let i = 0; i < state.fileList.length; i++) {
                let file = state.fileList[i];
                if (file.status == "uploading") {
                    Toast(t('LAN.upload_tips'))
                    return;
                }
                photos.push(file.url)
            }

            let contentJson = {
                content: state.feedContent,
            }
            let params = {
                type: state.menuType,
                username: state.userInfo.username,
                email: state.userInfo.email,
                gameId: store.getters.appId,
                roleId: parseInt(state.userInfo.roleId) || 0,
                photo: photos.length > 0 ? JSON.stringify(photos) : "",
            };

            let inpus = state.menus[state.menuType].inputs;
            for (let i = 0; i < inpus.length; i++) {
                let input = inpus[i];
                if (input.required) {
                    if (validateNull(input.value)) {
                        Toast.fail(t(input.placeholder))
                        return;
                    }
                }
                if (!input.contentJson) {
                    params[input.name] = input.value;
                } else {
                    contentJson[input.name] = input.value;
                }
            }
            
            if (!validateNull(state.userInfo.email)) {
                if (!validateEmail(state.userInfo.email)) {
                    Toast.fail(t('LAN.input_validator_email'));
                    return;
                }
            }
            
            if (validateNull(state.feedContent)) {
                Toast.fail(t('LAN.input_feedback'));
                return;
            }
            params.content = JSON.stringify(contentJson);

            Toast.loading(t('LAN.sending'));
            await requestFeedback(params);
            Toast.success(t('LAN.send_success'))
            initData();
        }

        const afterRead = (file) => {
            let upload = function(file) {
                file.status = 'uploading';
                file.message = t('LAN.uploading');
                console.log('file.file',file.file);
                
                uploadFile(file.file, function(url) {
                    if (url == "") {
                        file.status = 'failed';
                        file.message = t('LAN.upload_failed');
                    } else {
                        file.status = 'success';
                        file.message = '';
                        file.url = url
                    }
                })
            }
            if (file.length > 0) {
                for (let i = 0; i < file.length; i++) {
                    upload(file[i])
                }
            } else {
                upload(file)
            }
        }
     
        return {
            ...toRefs(state),
            selectMenu,
            onClickItem,
            onConfirmTime,
            onSubmit,
            afterRead
        }
    },
    components: {
        sHeader,
        orderHelper
    },
}

</script>

<style lang="less" scoped>
.top-bar {
    position: relative;
    width: 100%;
    height: 10px;
    background-color: #fff;
   // position: fixed;
    left: 0;
    right: 0;
    z-index: 9;
}

/* 用户反馈 */
.feedback-scroll {
    width: 100%;
    height: 100vh;
    overflow: auto;
    scroll-behavior: smooth;
}

.form-item-border-top {
    border-top: 1px solid #eee;
}

.form-item-border-bottom {
    border-bottom: none;
}

.feedback {
    padding: 70px 21px;
    background-color: #fff;
    word-break: break-all;

    .feedback-box {
        position: relative;
        overflow: hidden;
        .feedback-item {
            float: left;
            display: flex;
            align-items: center;
            justify-content: center;
            min-width: 115px;
            padding: 2px 0;
            margin-right: 5px;
            margin-bottom: 14px;
            background-color: #fff;
            border-radius: 5px;
            border: 1px solid #eee;
            
            .feed-text {
                font-size: 15px;
                word-break:normal;
                color: #b1b1b1;
            }
            
            .feed-icon {
                width: 20px;
                height: 20px;
                margin-right: 8px;
                background-size: 20px 20px;
            }
        }
        .feedback-item-active {
            background-color: #6e87ea;
            .feed-text {
                color: #fff;
            }
        }
    }
}

.feedback-form {
    input {
        padding: 0;
        border: 0;
        outline: none;
        -webkit-appearance: none;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .form-item {
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #eee;
        .form-left,
        .form-right {
            height: 100%;
            display: flex;
            align-items: center;
        }
        .form-right {
            height: 100%;
            align-items: center;
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }
        .form-value {
            font-size: 15px;
            color: #666;
        }
        .form-label {
            font-size: 16px;
            line-height: 16px;
            max-width: 200px;
            color: #333;
        }
        .form-icon-required {
            display: inline-block;
            margin: 0 5px;
            font-size: 15px;
            color: #ff6d6d;
        }
        .form-currency {
            font-size: 15px;
            color: #b1b1b1;
        }
        .form-input {
            width: 100%;
            height: 100%;
            text-align: right;
            font-size: 15px;
            color: #666;
        }
        .form-icon-click {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin: 0 5px;
            background: url("../../../assets/feedback/form-icon-click.png") no-repeat 50%;
            background-size: 18px 18px;
        }
        .form-textarea {
            width: 100%;
            height: 120px;
            padding: 0;
            border: 0;
            font-size: 15px;
            color: #666;
            outline: none;
            -webkit-appearance: none;
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
    }
    .form-item-upload {
        text-align: left;
        .upload-tips {
            font-size: 15px;
            color: #ff6d6d;
        }
    }
    .form-item-textarea {
        height: 120px;
        padding: 10px;
        border-radius: 2px;
        border: 1px solid #eee;
        border-top: none;
    }

    .orderLink {
        display: flex;
        font-size: 13px;
        float: right;
        margin-right: 25px;
        margin-top: 10px;
        color: green;
        text-decoration:underline
    }
}

iframe {
    height: 100%;
    width: 100%;
}
</style>