<template>
    <div class="pay">
        <div v-if="!showDetail">
            <van-cell center style="height:50px">
                <h3> {{$t('LAN.payhelp_title')}} </h3>
            </van-cell>

            <ul>
                <li v-for="(item, index) in payList" :key="index" @click="onClick(item)" class="wxplay">
                    <em><img :src="item.icon" class="icon" /></em>
                    <span class="text">{{ item.name }}</span>
                </li>
            </ul>
        </div>

        <div v-else>
            <header class="header van-hairline--bottom">
                <i class="headicon" @click="showDetail = false">
                    <van-icon name="arrow-left" />
                </i>
                <div class="header-name"> {{curItem.name}} </div>
            </header>
            
            <div class="help_body" id="help_body" :style="$L(1)">
                <h3>{{curItem.title}}</h3>
                <ul>
                  <li v-for="(str, index) in curItem.help" :key="index">{{str}}</li>
                </ul>
                <img :src="curItem.descImg"/>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive, onMounted, toRefs } from "vue";
import axios from "axios";
import store from "@/store";
import { LANG } from '@/configs/global'
import { useI18n } from 'vue-i18n'

export default {
  components: {},

  setup() {
    const { t } = useI18n()

    const state = reactive({
      payList: [
        {
            mode: "google",
            name: "Google Pay",
            icon: process.env.BASE_URL + "/img/payicon/googlepay.png",
            title: t('LAN.payhelp_google'),
            help:[
              t('LAN.payhelp_google1'),
              t('LAN.payhelp_google2'),
              t('LAN.payhelp_google3'),
              t('LAN.payhelp_google4'),
            ]
        },
        {
            mode: "wechat",
            name: "Wechat",
            icon: process.env.BASE_URL + "/img/payicon/wechat.png",
            title: t('LAN.payhelp_wechat'),
            help:[
              t('LAN.payhelp_wechat1'),
              t('LAN.payhelp_wechat2'),
              t('LAN.payhelp_wechat3'),
              t('LAN.payhelp_wechat4'),
              t('LAN.payhelp_wechat5'),
            ]
        },
        {
            mode: "paypal",
            name: "Paypal",
            icon: process.env.BASE_URL + "/img/payicon/paypal.png",
            title: t('LAN.payhelp_paypal'),
            help:[
              t('LAN.payhelp_paypal1'),
              t('LAN.payhelp_paypal2'),
              t('LAN.payhelp_paypal3'),
              t('LAN.payhelp_paypal4'),
            ]
        },
        {
            mode: "alipay",
            name: "Alipay",
            icon: process.env.BASE_URL + "/img/payicon/alipay.png",
            title: t('LAN.payhelp_alipay'),
            help:[
              t('LAN.payhelp_alipay1'),
              t('LAN.payhelp_alipay2'),
              t('LAN.payhelp_alipay3'),
              t('LAN.payhelp_alipay4'),
            ]
        },
      ],
      curItem:null,
      showDetail: false
    });

    onMounted(() => {
        for (let i = 0; i < state.payList.length; i++) {
            let pay = state.payList[i];
            let lang = store.getters.lang == LANG.zh_CN || store.getters.lang == LANG.zh_TW ? LANG.zh_CN : LANG.en_US
            pay.descImg = process.env.BASE_URL + `/img/help/pay/${pay.mode}-${lang}.png`;
        }
    });

    const onClick = async (item) => {
        state.curItem = item
        if (state.curItem.help == "") {
            let { data: help } = await axios.get(process.env.BASE_URL +  `/data/payhelp/${item.mode}-zh_CN.txt`);
            state.curItem.help = help
        }
        state.showDetail = true
    }
    return {
      ...toRefs(state),
      onClick,
    };
  },
};
</script>

<style scoped lang="less">


.wxplay{
  height:30px; padding:14px 20px; line-height:30px; position:relative; clear:both; display:block; overflow:hidden; border-top:1px solid #ebebeb;
  .icon {
    float:left; width:55px; height:40px; margin-top: -5px; margin-left: -6px;
  }
  span {
    display:block; float:left;
    padding-left:12px; padding-right:10px;
    font-size:16px;
  }
}



.help_body {
    margin-top: 30px;
    font-size: 16px;
    padding: 20px;
    box-sizing: border-box;
    background-color: #fff;
    white-space: pre-wrap;
    word-wrap: break-word;
    word-break: break-all;
    text-align: left;
    li {
      line-height: 25px;
    }
    img {
      width: 100%;
      margin-top: 10px;
    }
}

.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    background-color: #fff;
    padding: 10px 0px;
    justify-content: center;
    .headicon {
      position: absolute;
      left: 10px;
      font-size: 23px;
    }
    .header-name {
        text-align: center;
        width: 100%;
        font-size: 16px;
        line-height: 30px;
    }
}
</style>
